import { createRouter, createWebHistory } from 'vue-router';
import Login from './views/Login.vue'
import Register from './views/Register.vue'
import Canvas from './views/Canvas.vue'


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            redirect: "/account",
        },
        {
            path: "/account/login",
            name: "login",
            component: Login,
        },
        {
            path: "/account/register",
            name: "register",
            component: Register,
        },
        {
            path: "/account/canvas",
            name: "canvas",
            component: Canvas,
        }
    ]
});

export default router
