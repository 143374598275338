/****
 * 主要功能：登录页面
 * 创建者：宝城
 * 创建日期：2021-09-26
 */

// import Vue from 'vue'
import { createApp } from 'vue'
import App from './Account.vue'
import router from './router'
import store from './store'
import element from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import components from "@/components/components.js";
import 'element-plus/theme-chalk/index.css'
// import '@/assets/styles/fontawesome/css/font-awesome.min.css'
import '@/assets/styles/common.css'
// import common from "@/assets/scripts/common.js";
// import cookies from "@/assets/scripts/cookies.js";
// import message from "@/assets/scripts/message.js";
// import api from "@/assets/scripts/api.js";


const app = createApp(App);
// app.config.globalProperties.common = common;
// app.config.globalProperties.cookies = cookies;
// app.config.globalProperties.message = message;
// app.config.globalProperties.api = api;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(store)
    .use(router)
    .use(element)
    .use(components)
    .mount('#app');
