<template>
    <div class="background">
        <el-container>
            <el-aside width="1200px" style="padding: 20px">
                <div
                    class="container"
                    :ref="
                        (el) => {
                            canvas.container = el;
                        }
                    "
                >
                    <canvas
                        :ref="
                            (el) => {
                                canvas.element = el;
                            }
                        "
                        :width="canvas.width"
                        :height="canvas.height"
                        style="border: 1px solid black"
                    ></canvas>
                </div>
            </el-aside>
            <el-main style="padding-left: 0px">
                <div class="container" style="padding: 20px">
                    <el-form label-width="100px">
                        <el-form-item label="锁定比率">
                            <el-checkbox
                                v-model="params.lockPos"
                                :true-value="true"
                                :false-value="false"
                                @change="
                                    (val) => {
                                        onLockChange('pos', val);
                                    }
                                "
                            ></el-checkbox>
                        </el-form-item>
                        <el-form-item label="原点X">
                            <el-slider
                                v-model="params.pos.x"
                                :max="params.pos.maxx"
                                :min="params.pos.minx"
                                :step="1"
                                @input="
                                    (val) => {
                                        onZoomChange('posx', val);
                                    }
                                "
                            ></el-slider>
                        </el-form-item>
                        <el-form-item label="原点Y">
                            <el-slider
                                v-model="params.pos.y"
                                :max="params.pos.maxy"
                                :min="params.pos.miny"
                                :step="1"
                                @input="
                                    (val) => {
                                        onZoomChange('posy', val);
                                    }
                                "
                            ></el-slider>
                        </el-form-item>
                        <el-form-item label="锁定比率">
                            <el-checkbox
                                v-model="params.lockZoom"
                                :true-value="true"
                                :false-value="false"
                                @change="
                                    (val) => {
                                        onLockChange('zoom', val);
                                    }
                                "
                            ></el-checkbox>
                        </el-form-item>
                        <el-form-item label="X缩放">
                            <el-slider
                                v-model="params.zoom.x"
                                :max="params.zoom.maxx"
                                :min="params.zoom.minx"
                                :step="0.1"
                                @input="
                                    (val) => {
                                        onZoomChange('zoomx', val);
                                    }
                                "
                            ></el-slider>
                        </el-form-item>
                        <el-form-item label="Y缩放">
                            <el-slider
                                v-model="params.zoom.y"
                                :max="params.zoom.maxy"
                                :min="params.zoom.miny"
                                :step="0.1"
                                @input="
                                    (val) => {
                                        onZoomChange('zoomy', val);
                                    }
                                "
                            ></el-slider>
                        </el-form-item>
                        <el-form-item label="旋转">
                            <el-slider
                                v-model="params.angle.val"
                                :max="params.angle.maxval"
                                :min="params.angle.minval"
                                :step="0.1"
                                @input="
                                    (val) => {
                                        onZoomChange('angle', val);
                                    }
                                "
                            ></el-slider>
                        </el-form-item>
                    </el-form>
                    <img src="../../../assets/images/login.png" alt="" />
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script setup>
import { onMounted, ref, reactive } from "vue";
import { message, api, cookies } from "@/assets/scripts/common.js";
const refAccount = ref(null);
const account = reactive({
    userName: "",
    password: "",
});
const params = reactive({
    lockPos: false,
    pos: {
        maxx: 100,
        minx: 0,
        maxy: 100,
        miny: 0,
        x: 1,
        y: 1,
    },
    lockZoom: false,
    zoom: {
        maxx: 10,
        minx: 1,
        maxy: 10,
        miny: 1,
        x: 1,
        y: 1,
    },
    angle: {
        minval: 0,
        maxval: 360,
        val: 0,
    },
});
const canvas = reactive({
    container: null,
    element: null,
    width: 300,
    height: 150,
});

let _img = new Image();
_img.onload = (e) => {
    console.log(e);
    _img.loaded = true;
};
_img.onerror = (e) => {
    console.log(e);
    _img.error = true;
};
_img.src = "/test/1.jpg";
let _ctx = null;
var deg = Math.PI / 180;

onMounted(() => {
    let width = canvas.container.offsetWidth - 20;
    let height = canvas.container.offsetHeight - 20;
    if (width < height) {
        params.pos.maxx = canvas.width = width;
        params.pos.maxy = canvas.height = width;
    } else {
        params.pos.maxx = canvas.width = height;
        params.pos.maxy = canvas.height = height;
    }
    // params.pos.maxx = canvas.width = canvas.container.offsetWidth;
    // params.pos.maxy = canvas.height = canvas.container.offsetHeight;
    _ctx = canvas.element.getContext("2d");
    setTimeout(() => {
        paint();
    }, 500);
});

function drawLines() {
    //线段
    let ps = [{ x: 10, y: 10 }];
    for (let i = 0; i < 5; i++) {
        ps.push({
            x: Math.floor(Math.random() * canvas.width),
            y: Math.floor(Math.random() * canvas.height),
        });
    }
    ps.push({ x: canvas.width, y: canvas.height });
    //直线
    _ctx.beginPath();
    _ctx.moveTo(ps[0].x, ps[0].y);
    for (let i = 1; i < ps.length; i++) {
        _ctx.lineTo(ps[i].x, ps[i].y);
        // _ctx.quadraticCurveTo(200, 400, 400, 400);
    }
    _ctx.stroke();
    //贝塞尔曲线
    _ctx.strokeStyle = "red";
    _ctx.beginPath();
    _ctx.moveTo(ps[0].x, ps[0].y);
    // _ctx.lineTo(ps[1].x, ps[1].y);
    let pss = [];
    for (let i = 2; i < ps.length; i++) {
        let pa = ps[i - 2];
        let pb = ps[i - 1];
        let pc = ps[i];
        let pd = { x: (pa.x + pb.x) / 2, y: (pa.y + pb.y) / 2 };
        let pe = { x: (pb.x + pc.x) / 2, y: (pb.y + pc.y) / 2 };
        let pf = { x: (pd.x + pe.x) / 2, y: (pd.y + pe.y) / 2 };
        let pg = { x: pd.x + (pb.x - pf.x), y: pd.y + (pb.y - pf.y) };
        let ph = { x: pe.x + (pb.x - pf.x), y: pe.y + (pb.y - pf.y) };
        pss.push([pd, pe]);
        pss.push([pg, ph]);
        _ctx.quadraticCurveTo(pg.x, pg.y, pb.x, pb.y);
        if (i == ps.length - 1) {
            _ctx.quadraticCurveTo(ph.x, ph.y, pc.x, pc.y);
        }
        //_ctx.quadraticCurveTo(ps[i].x, ps[i].y, ps[i + 1].x, ps[i + 1].y);
        // let x = (ps[ps.length - 2].x + ps[ps.length - 1].x) / 2,
        //     y = (ps[ps.length - 2].y + ps[ps.length - 1].y) / 2;
        // if (ps.length == 2) {
        //     _ctx.moveTo(ps[ps.length - 2].x, ps[ps.length - 2].y);
        //     _ctx.lineTo(x, y);
        // } else {
        //     let lastX = (ps[ps.length - 3].x + ps[ps.length - 2].x) / 2,
        //         lastY = (ps[ps.length - 3].y + ps[ps.length - 2].y) / 2;
        //     _ctx.moveTo(lastX, lastY);
        //     _ctx.quadraticCurveTo(
        //         ps[ps.length - 2].x,
        //         ps[ps.length - 2].y,
        //         x,
        //         y
        //     );
        // }
    }
    _ctx.stroke();
    _ctx.strokeStyle = "blue";
    for (let i = 0; i < pss.length; i++) {
        _ctx.beginPath();
        _ctx.moveTo(pss[i][0].x, pss[i][0].y);
        for (let j = 1; j < pss[i].length; j++) {
            _ctx.lineTo(pss[i][j].x, pss[i][j].y);
        }
        _ctx.stroke();
    }
}

function paint() {
    _ctx.save();
    _ctx.clearRect(0, 0, canvas.element.width, canvas.element.height);
    let fontSize = 40;
    let text = "Hello world!";
    _ctx.font = `${fontSize}px 宋体 bold`;
    _ctx.textBaseline = "top";
    //_ctx.transform(params.zoom.x, 0, 0, params.zoom.y, 0, 0);
    // 参数	描述
    // a	水平缩放绘图
    // b	水平倾斜绘图
    // c	垂直倾斜绘图
    // d	垂直缩放绘图
    // e	水平移动绘图
    // f	垂直移动绘图
    _ctx.transform(
        params.zoom.x * Math.cos(deg * params.angle.val),
        Math.sin(deg * params.angle.val),
        -Math.sin(deg * params.angle.val),
        params.zoom.y * Math.cos(deg * params.angle.val),
        params.pos.x,
        params.pos.y
    );
    _ctx.beginPath();
    _ctx.arc(0, 0, 10, 0, 2 * Math.PI);
    _ctx.fill();
    let tw = _ctx.measureText(text).width;
    _ctx.strokeText(text, -tw / 2, -20);
    _ctx.strokeText(text, 100, 100);
    _ctx.strokeRect(100 - 10, 100 - 10, tw + 20, fontSize + 10);
    //图片
    if (_img.loaded) {
        _ctx.drawImage(_img, 0, 0, canvas.width, canvas.height);
    }

    drawLines();
    _ctx.restore();
}

function onLockChange(type, val) {
    switch (type) {
        case "pos":
            params.pos.y = params.pos.x;
            break;
        case "zoom":
            params.zoom.y = params.zoom.x;
            break;
        default:
            break;
    }
    paint();
}

function onZoomChange(type, val) {
    switch (type) {
        case "posx":
            if (params.lockPos) {
                params.pos.y = val;
            }
            break;
        case "posy":
            if (params.lockPos) {
                params.pos.x = val;
            }
            break;
        case "zoomx":
            if (params.lockZoom) {
                params.zoom.y = val;
            }
            break;
        case "zoomy":
            if (params.lockZoom) {
                params.zoom.x = val;
            }
            break;
        default:
            break;
    }
    paint();
}
</script>

<style scoped>
#app,
.background {
    position: relative;
    min-height: 100vh;
    min-width: 1264px;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to right bottom, white, #15cbe2, #20c3ff);
    display: flex;
    flex-direction: row;
}
.container {
    border: 1px solid silver;
    height: 100%;
    box-sizing: border-box;
}
</style>
