import message from "./message.js";
import cookies from "./cookies.js";
import api from "./api.js";

const images = {
    headerLogo: require("@/assets/images/header-logo.png"),
    defaultProduct: require("@/assets/images/product.png"),
    default: {
        person: require("@/assets/images/default/person.png"),
    },
};

const math = {
    round: function (num, v) {
        var vv = Math.pow(10, v);
        return Math.round(num * vv) / vv;
    },
    floor: function (num, v) {
        var vv = Math.pow(10, v);
        return Math.floor(num * vv) / vv;
    },
    ceil: function (num, v) {
        var vv = Math.pow(10, v);
        return Math.ceil(num * vv) / vv;
    },
};

function Lookup(values) {
    this.values = values;
    this.val = function (key) {
        let val = this.values.find((item) => { return item.key == key; });
        return val ? val.value : null;
    }
    this.key = function (val) {
        let item = this.values.find((item) => { return item.value == val; });
        return item ? item.key : nhll;
    }
}

const lookups = {
    userStatus: new Lookup([//用户状态
        { key: 0, value: "未激活" },
        { key: 1, value: "已激活" },
        { key: 2, value: "锁定" },
        { key: 3, value: "删除" }
    ]),
    orderStatus: new Lookup([//采购单状态
        { key: 0, value: "暂存" },
        { key: 1, value: "提交" },
        { key: 2, value: "审核" },
        { key: 3, value: "采购" },
        { key: 4, value: "入库" },
    ]),
    productStatus: new Lookup([//产品状态
        { key: 0, value: "正常" },
        { key: 1, value: "停用" },
        { key: 2, value: "删除" },
    ]),
    regStatus: new Lookup([//扫码登记状态
        { key: 0, value: "未知" },
        { key: 1, value: "正常" },
        { key: 2, value: "跨区" },
    ]),
    roleTypes: new Lookup([//角色类型
        { key: 0, value: "默认" },
        { key: 1, value: "自定义" },
    ]),
    goldRuleTypes: new Lookup([//角色类型
        { key: 1, value: "扫码登记" },
    ]),
};


const validators = {
    isPhone: (rule, value, callback) => {
        let isPhone = new RegExp("^1[3456789][0-90{9}$]", "i");
        if (isPhone.test(value)) {
            callback();
        } else {
            callback("手机号不合法");
        }
    },
    isEmail: (rule, value, callback) => {
        let isEmail = new RegExp(
            "^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$",
            "i"
        );
        if (isEmail.test(value)) {
            callback();
        } else {
            callback("邮箱地址不合法");
        }
    },
}

const makeSpecis = function (specis) {
    let res = [];
    for (let i = 0; i < specis.length; i++) {
        let speci = specis[i];
        let a = res;
        res = [];
        let checked = speci.checked;
        if (!Array.isArray(checked)) {//兼容单选钮
            checked = [checked];
        }
        for (let j = 0; j < checked.length; j++) {
            if (a.length == 0) {
                res.push(checked[j].value);
            } else {
                for (let k = 0; k < a.length; k++) {
                    res.push(a[k] + "/" + checked[j].value);
                }
            }
        }
    }
    return res;
}

export {
    message,
    cookies,
    api,
    images,
    math,
    lookups,
    validators,
    makeSpecis,
}


// &#32; == 普通的英文半角空格
// &#160; == &nbsp; == &#xA0; == no-break space （普通的英文半角空格但不换行）
// &#12288; == 中文全角空格 （一个中文宽度）
// &#8194; == &ensp; == en空格 （半个中文宽度）
// &#8195; == &emsp; == em空格 （一个中文宽度）
// &#8197; == 四分之一em空格 （四分之一中文宽度）
// 相比平时的空格（&#32;），&nbsp拥有不间断（non-breaking）特性。即连续的&nbsp会在同一行内显示。即使有100个连续的&nbsp，浏览器也不会把它们拆成两行。