<template>
    <div class="login">
        <img class="login-logo" src="@/assets/images/logo.png" />
        <div class="login-center">
            <div class="img-warper">
                <img
                    class="page-login-slogan"
                    src="../../../assets/images/login-slogan.png"
                    alt=""
                />
                <img
                    class="page-login-bg"
                    src="../../../assets/images/login.png"
                    alt=""
                />
            </div>
            <div class="form-warper">
                <el-form
                    ref="refAccount"
                    :model="account"
                    :rules="ruleAccount"
                    label-width="auto"
                >
                    <div class="login-panel">
                        <div style="font-size: 20px; margin-bottom: 20px">
                            登录
                        </div>
                        <el-form-item prop="userName">
                            <el-input
                                placeholder="手机号"
                                v-model="account.userName"
                            >
                                <template #prepend
                                    ><el-icon :size="20"
                                        ><Icon icon="psi-username" /></el-icon
                                ></template>
                            </el-input>
                        </el-form-item>
                        <el-form-item
                            prop="password"
                            style="margin-bottom: 15px"
                        >
                            <el-input
                                placeholder="密码"
                                v-model="account.password"
                                :show-password="true"
                                @keypress="onKeypressPassword"
                            >
                                <template #prepend
                                    ><el-icon :size="20"
                                        ><Icon icon="psi-lock" /></el-icon
                                ></template>
                            </el-input>
                        </el-form-item>
                        <router-link to="register">
                            <a
                                href="javascript:void(0);"
                                class="font-link"
                                style="
                                    display: inline-block;
                                    margin-bottom: 10px;
                                "
                                >注册/重置密码</a
                            >
                        </router-link>
                        <el-form-item label="">
                            <el-button
                                type="primary"
                                @click="onClickLogin('refAccount')"
                                style="width: 100%"
                                >登录</el-button
                            >
                        </el-form-item>
                        <el-form-item label="">
                            <span
                                ><span
                                    ><el-checkbox
                                        style="vertical-align: middle" /></span
                                ><span class="pointer primary"
                                    >《建木康养用户协议》</span
                                >和<span class="pointer primary"
                                    >《建木康养隐私协议》</span
                                ></span
                            >
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="login-footer">
            <span
                ><a href="https://beian.miit.gov.cn" target="_blank"
                    >粤ICP备2023060159号-1</a
                ></span
            >
            <!-- <span
                style="
                    display: flex;
                    flex-flow: row nowarp;
                    items-align: center;
                "
                >
                <img src="@/assets/images/record.png" />
                <a href="#" target="_blank">粤*****</a>
            </span> -->
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, reactive } from "vue";
import { message, api, cookies } from "@/assets/scripts/common.js";
const refAccount = ref(null);
const account = reactive({
    userName: "",
    password: "",
});
const ruleAccount = reactive({
    userName: [
        {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
        },
        {
            min: 11,
            max: 11,
            message: "手机号长度不合法",
            trigger: "blur",
        },
    ],
    password: [{ required: true, message: "请输入密码", trigger: "blur" }],
});

function onClickLogin() {
    refAccount.value.validate((valid) => {
        if (valid) {
            api.login({
                username: account.userName,
                password: account.password,
            })
                .then(function (data) {
                    cookies.token(data.token);
                    cookies.user(data.user);
                    // console.log(JSON.stringify(data.user));
                    window.location = "/main";
                })
                .catch(function (err) {
                    message.error(err.message);
                });
        } else {
            $message.error("Fail!");
        }
    });
}

onMounted(() => {
    if (process.env.NODE_ENV == "debug") {
        account.userName = "18618132730";
        account.password = "wangdatou";
    }
});
function onKeypressPassword(e) {
    if (e.keyCode == 13) {
        onClickLogin();
    }
}
</script>

<style scoped>
#app,
.login {
    position: relative;
    min-height: 100vh;
    min-width: 1264px;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to right bottom, white, #15cbe2, #20c3ff);
    /* background-image: radial-gradient(
        circle closest-corner at 12% 15%,
        transparent,
        white,
        #15cbe2 180%,
        #20c3ff
    ); */
    display: flex;
    flex-direction: row;
}
.login-logo {
    position: absolute;
    top: 15px;
    left: 20px;
    height: 200px;
}
.login-center {
    position: relative;
    margin: 0 auto;
    min-width: 1200px;
    display: flex;
    flex-direction: row;
}
.img-warper {
    position: absolute;
    top: 50%;
    right: 450px;
    left: 0;
    overflow: hidden;
    max-height: 580px;
    height: 92%;
    transform: translateY(-50%);
}
.page-login-slogan {
    position: absolute;
    left: 10px;
    z-index: 1;
    height: 45px;
}
.page-login-bg {
    position: absolute;
    bottom: -10px;
    left: -60px;
}
.form-warper {
    position: absolute;
    top: 40%;
    right: 0;
    padding: 40px;
    width: 440px;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0 1px 1px 0 rgb(60 75 100 / 14%),
        0 2px 1px -1px rgb(60 75 100 / 12%), 0 1px 3px 0 rgb(60 75 100 / 20%);
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
}
.login-footer {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}
.login-footer > span {
    margin: 10px;
}
.login-footer a {
    outline: none;
    text-decoration: none;
    color: rgb(233, 228, 228);
}
</style>
