<template>
    <el-container>
        <el-header>
            <div><img src="../../../assets/images/logo.png" /></div>
            <div><el-button size="medium" type="text">下载</el-button></div>
        </el-header>
        <el-main>
            <el-row>
                <el-col :offset="5" :span="14">
                    <div class="single-page">
                        <div class="single-page-header font-title">
                            <i
                                class="fa fa-address-card-o fa-lg"
                                size="26"
                            /><span style="padding-left: 5px">找回密码</span>
                        </div>
                        <div style="padding: 50px 250px">
                            <el-form
                                ref="account"
                                :model="account"
                                label-width="100px"
                                :rules="ruleAccount"
                                label-position="right"
                            >
                                <el-form-item label="手机号：" prop="phone">
                                    <el-input
                                        search
                                        :disabled="phoneInputDisable"
                                        v-model="account.phone"
                                        placeholder="请输入手机号..."
                                    >
                                        <el-button
                                            slot="append"
                                            type="primary"
                                            @click="onClickSendVerify"
                                            >{{ sendText }}</el-button
                                        >
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="姓名：" prop="userName">
                                    <el-input
                                        v-model="account.userName"
                                        placeholder="请输入姓名..."
                                    ></el-input>
                                </el-form-item>
                                <el-form-item
                                    label="验证码："
                                    prop="verifyCode"
                                >
                                    <el-input
                                        v-model="account.verifyCode"
                                        placeholder="请输入验证码..."
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="密码：" prop="password">
                                    <el-input
                                        type="password"
                                        v-model="account.password"
                                        show-password="true"
                                        placeholder="请输入密码..."
                                    ></el-input>
                                </el-form-item>
                                <el-form-item
                                    label="确认密码："
                                    prop="confirmPassword"
                                >
                                    <el-input
                                        type="password"
                                        v-model="account.confirmPassword"
                                        show-password="true"
                                        placeholder="请再次输入密码..."
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-button
                                        type="primary"
                                        long
                                        @click="onClickSubmit('account')"
                                        style="width: 100%"
                                        >注册/找回密码</el-button
                                    >
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-main>
        <el-footer>
            <a href="javascript:void(0);"
                >Copyright © 2012-2020 Kuyi All Rights Reserved.
            </a>
            <a target="_blank" href="https://beian.miit.gov.cn/"
                >京ICP备14018361号-1</a
            >
        </el-footer>
    </el-container>
</template>

<script>
export default {
    data: function () {
        return {
            account: {
                phone: "18618132730",
                userName: "",
                verifyCode: "",
                password: "",
                confirmPassword: "",
            },
            ruleAccount: {
                phone: [
                    {
                        required: true,
                        message: "请输入手机号",
                        trigger: "blur",
                    },
                    {
                        min: 11,
                        max: 11,
                        message: "手机号长度不合法",
                        trigger: "blur",
                    },
                ],
                userName: [
                    { required: true, message: "请输入姓名", trigger: "blur" },
                ],
                verifyCode: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                    },
                    {
                        min: 6,
                        max: 6,
                        message: "验证码长度不合法",
                        trigger: "blur",
                    },
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                    {
                        type: "string",
                        min: 6,
                        max: 25,
                        message: "密码长度要求6-25位",
                        trigger: "blur",
                    },
                ],
                confirmPassword: [
                    {
                        required: true,
                        message: "请再次输入密码",
                        trigger: "blur",
                    },
                    {
                        type: "string",
                        min: 6,
                        max: 25,
                        message: "密码长度要求6-25位",
                        trigger: "blur",
                    },
                    {
                        type: "string",
                        validator: (rule, value, callback) => {
                            if (value == this.account.password) callback();
                            else callback(new Error("equal error"));
                        },
                        message: "两次输入的密码不一致",
                        trigger: "blur",
                    },
                ],
            },
            sendText: "发送验证码",
            phoneInputDisable: false,
            countDown: 60,
        };
    },
    methods: {
        onClickSubmit: function (p) {
            //alert(p);
            window.location = "/read";
        },
        onClickSendVerify: function (value) {
            this.countDown = 60;
            this.sendText = this.countDown + "秒";
            this.phoneInputDisable = true;
            this.countDownSend();
        },
        countDownSend: function () {
            setTimeout(() => {
                this.countDown--;
                if (this.countDown == 0) {
                    this.phoneInputDisable = false;
                    this.sendText = "发送验证码";
                } else {
                    this.sendText = this.countDown + "秒";
                    this.countDownSend();
                }
            }, 1000);
        },
        onClickSubmit: function (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$Message.success("Success!");
                } else {
                    this.$Message.error("Fail!");
                }
            });
        },
    },
};
</script>

<style scoped></style>
